import Barcode from "react-barcode";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Divider from "@mui/material/Divider";
import PrintExample from "./PrintExample";
import Result from "../Worksheets/Result";
import Final from "../Worksheets/Final";
import "./Print.css";

const PrintPage = (props) => {
  const data = props.data;
  const title = "Jméno a příjmení:";
  const description = props.description;
  const userInterface = props.userInterface;

  let resultsTextElement;
  if (data.numbersDecimals > 0) {
    if (data.numbersDecimals === 1) {
      resultsTextElement = (
        <p className="mb-4">
          Vypočti se zaokrouhlením na {data.numbersDecimals} desetinné místo.
        </p>
      );
    } else {
      resultsTextElement = (
        <p className="mb-4">
          Vypočti se zaokrouhlením na {data.numbersDecimals} desetinná místa.
        </p>
      );
    }
  }

  return (
    <div className="page">
      <Container fluid>
        <Row>
          <Col>
            {title}
          </Col>
          <Col>
            <div className="flex justify-end">
              <samp>{data.hash}</samp>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="page__content">
        <Container fluid>
          <Row>
            <Col>
              <div className="mb-8">
                <p>{ description }</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Divider textAlign="left" className="mb-4">Příklady</Divider>
              {resultsTextElement}
              {data.examples.map((item, index) => (
                <PrintExample
                  key={index}
                  number1={item.number1}
                  operator={item.operator}
                  number2={item.number2}
                  result={item.result}
                  showResult={false}
                  userInterface={userInterface}
                />
              ))}
            </Col>
            <Col>
              <Divider textAlign="left" className="mb-4">Možné výsledky</Divider>
              <div className="d-flex flex-row flex-wrap">
                {data.results.map((item, index) => (
                  <Result
                    key={index}
                    result={item.value}
                    type={item.type}
                    showType={false}
                  />
                ))}
              </div>

              <Divider textAlign="left" className="mt-4 mb-4">Konečný výsledek</Divider>
              <Final result={data.final} showResult={false} />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PrintPage;
