import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "../../UI/Section/Section";

const ThankYou = () => {
  return (
    <Section>
      <Row className="flex items-center">
        <Col>
          <div className="md:px-4 text-xs">
            <p className="mb-2">Settlers - Generátor pracovních listů do matematiky. &copy; 2021-2022 <a className="underline" href="http://www.musiol.cz/" target="_blank" rel="noreferrer">Tomáš Musiol</a> &amp; <a className="underline" href="http://www.nase-trida.cz/" target="_blank" rel="noreferrer">Naše třída</a>.</p>
            <p>Pokud se Vám tato aplikace líbí budu rád, když se <a className="underline" href="https://nasetrida.substack.com" target="_blank" rel="noreferrer">přihlásíte k odběru newsletteru</a>, ve kterém informuji o novinkách v této aplikaci.</p>
            <p>Máte nápad na novou funkci nebo jste narazili na chybu, prosím <a className="underline" href="https://forms.gle/dPSSKTifArpH1MJaA" target="_blank" rel="noreferrer">napište mi zpětnou vazbu</a> (Google Formulář).</p>
          </div>
        </Col>
      </Row>
    </Section>
  );
};

export default ThankYou;
