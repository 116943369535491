export const numberWithSpace = (num) => {
    let number = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if( num.includes(",") ){
      const numExplode = num.split(",");
      number = numExplode[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") +","+ numExplode[1];
    }

    return number;
}

export const localFormat = (num, parseNegative = true) => {
  let numString = num.toString().replace(" ", "").replace(".", ",").replace("-", "−");
  if (num < 0 && parseNegative) {
    numString = "(" + numString + ")";
  }
  return numberWithSpace(numString);
};
