import { localFormat } from "../../Helpers/format";
import "./Final.css";

const Final = (props) => {
  const result = localFormat(props.result, false);
  const showResult = props.showResult;

  let finalElement = "";
  if( showResult ){
    finalElement = <div className="final rounded">{result}</div>;
  }

  return (
    <div className="d-flex align-items-center">
      {finalElement}
    </div>
  );
};

export default Final;
