import { localFormat } from "../../Helpers/format";
import "./PrintExample.css";

const PrintExample = (props) => {
  const number1 = localFormat(props.number1);
  const operator = props.operator;
  const number2 = localFormat(props.number2);
  const userInterface = props.userInterface;

  let writtenCounting = userInterface.writtenCounting.checked;

  let exampleElement = (
    <div className="flex">
      <span className="printExample printExample--number rounded mr-2">
        {number1}
      </span>
      <span className="printExample printExample--sign rounded mr-2">
        {operator}
      </span>
      <span className="printExample printExample--number rounded mr-2">
        {number2}
      </span>
      <span className="printExample printExample--sign rounded mr-2">=</span>
      <span className="printExample printExample--result rounded pl-3"></span>
    </div>
  );
  if (writtenCounting) {
    exampleElement = (
      <div className="flex mb-2">
        <span className="printExample printExample--sign rounded mr-2">
          {operator}
        </span>
        <span className="printExample rounded mr-2">
          <span className="printExample--subnumber printExample--subnumber-first">
            {number1}
          </span>
          <span className="printExample--subnumber printExample--subnumber-last">
            {number2}
          </span>
        </span>
        <span className="printExample printExample--sign printExample--sign-equal rounded mr-2">
          =
        </span>
        <span className="printExample printExample--result rounded pl-3"></span>
      </div>
    );
  }

  let classes = "printExample-item ";
  if (writtenCounting) {
    classes = classes + "printExample-item-written-counting";
  }
  return <div className={classes}>{exampleElement}</div>;
};

export default PrintExample;
