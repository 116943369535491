const evaluateExample = (number1, operator, number2, numbersDecimals) => {
  let result = 0;
  const num1 = Number(number1);
  const num2 = Number(number2);

  // sestaveni vypoctu na zaklade operatoru
  switch (operator) {
    case "+":
      result = num1 + num2;
      break;
    case "-":
      result = num1 - num2;
      break;
    case "×":
      result = num1 * num2;
      break;
    case ":":
      result = num1 / num2;
      break;
    default:
      result = num1 + num2;
  }

  return result.toFixed(numbersDecimals);
};

export default evaluateExample;
