import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Example from "./Example";
import Result from "./Result";
import Final from "./Final";
import { localFormat } from "../../Helpers/format";

const Worksheet = (props) => {
  const data = props.data;
  const index = props.index;
  const userInterface = props.userInterface;
  const description = props.description;

  let results;
  if( userInterface.showResults.checked === true ){
    results = <Col xs={4}><div className="flex items-center justify-end"><Chip variant="outlined" label={localFormat(data.final)} size="small" /></div></Col>
  }

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Container>
          <Row className="flex items-center">
            <Col xs={8}><samp>{data.hash}</samp></Col>
            {results}
          </Row>
        </Container>
      </AccordionSummary>
      <AccordionDetails>
        <Container>
        <Row className="mb-4">
            <Col md={12}>
              <Divider textAlign="left" className="mb-4">Zadání</Divider>
              <p className="text-sm mb-4">{description}</p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12}>
              <Divider textAlign="left" className="mb-4">Vygenerované příklady</Divider>
              <div className="example-wrapper">
                {data.examples.map((item, index) => (
                  <Example
                    key={index}
                    number1={item.number1}
                    operator={item.operator}
                    number2={item.number2}
                    result={item.result}
                    showResult={true}
                    userInterface={userInterface}
                  />
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={7}>
              <Divider textAlign="left" className="mb-4">Možné výsledky</Divider>
              <div className="d-flex flex-row flex-wrap ">
                {data.results.map((item, index) => (
                  <Result
                    key={index}
                    result={item.value}
                    type={item.type}
                    showType={true}
                  />
                ))}
              </div>
            </Col>
            <Col md={5}>
              <Divider textAlign="left" className="mb-4">Konečný výsledek</Divider>
              <Final result={data.final} showResult={userInterface.showResults.checked} />
            </Col>
          </Row>
        </Container>
      </AccordionDetails>
    </Accordion>
  );
};

export default Worksheet;
