import cryptoRandomString from 'crypto-random-string';

const generateHash = ( counter ) => {
    // pocet znaku pro generovani hashe podle poctu zvolenych prikladu
    let lengthLevel = 3;
    if( counter >= 30 && counter < 60 ){ lengthLevel = 4; }
    if( counter >= 60 ){ lengthLevel = 5; }

    // generovani unikatniho hashe
    return cryptoRandomString({ length: lengthLevel, characters: 'ABCDEFGH' });
}

export default generateHash;
