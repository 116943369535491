import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import Container from "react-bootstrap/Container";
import Header from "./Components/Header/Header";
import Settings from "./Components/Settings/Settings";
import Worksheets from "./Components/Worksheets/Worksheets";
import ThankYou from "./Components/ThankYou/ThankYou";

import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./App.css";

function App() {
  // ---------------------------------
  // pocet pracovnich listu
  // ---------------------------------

  const [lists, setLists] = useState(
    localStorage.getItem("lists")
      ? JSON.parse(localStorage.getItem("lists"))
      : 10
  );

  const listsHandler = (value) => {
    setLists(value);
    localStorage.setItem("lists", JSON.stringify(value));
  };


  // ---------------------------------
  // pocet prikladu na pracovni list
  // ---------------------------------

  const [examples, setExamples] = useState(
    localStorage.getItem("examples")
      ? JSON.parse(localStorage.getItem("examples"))
      : 20
  );

  const examplesHandler = (value) => {
    setExamples(value);
    localStorage.setItem("examples", JSON.stringify(value));
  };


  // ---------------------------------
  // minimalni cislo pro generovani cisel
  // ---------------------------------

  const [number1RangeMin, setNumber1RangeMin] = useState(
    localStorage.getItem("number1RangeMin")
      ? JSON.parse(localStorage.getItem("number1RangeMin"))
      : 0
  );

  const [number2RangeMin, setNumber2RangeMin] = useState(
    localStorage.getItem("number2RangeMin")
      ? JSON.parse(localStorage.getItem("number2RangeMin"))
      : 0
  );

  const number1RangeMinHandler = (value) => {
    let valueNormal = "";
    if( !isNaN(value) ){
      valueNormal = Number(value);
    }

    // ulozeni minima pro rozsahy
    setNumber1RangeMin(valueNormal);
    localStorage.setItem("number1RangeMin", JSON.stringify(valueNormal));
  };

  const number2RangeMinHandler = (value) => {
    let valueNormal = "";
    if( !isNaN(value) ){
      valueNormal = Number(value);
    }

    // ulozeni minima pro rozsahy
    setNumber2RangeMin(valueNormal);
    localStorage.setItem("number2RangeMin", JSON.stringify(valueNormal));
  };


  // ---------------------------------
  // maximalni cislo pro generovani cisel
  // ---------------------------------

  const [number1RangeMax, setNumber1RangeMax] = useState(
    localStorage.getItem("number1RangeMax")
      ? JSON.parse(localStorage.getItem("number1RangeMax"))
      : 100
  );

  const [number2RangeMax, setNumber2RangeMax] = useState(
    localStorage.getItem("number2RangeMax")
      ? JSON.parse(localStorage.getItem("number2RangeMax"))
      : 100
  );

  const number1RangeMaxHandler = (value) => {
    let valueNormal = "";
    if( !isNaN(value) ){
      valueNormal = Number(value);
    }

    // ulozeni maxima pro rozsahy
    setNumber1RangeMax(valueNormal);
    localStorage.setItem("number1RangeMax", JSON.stringify(valueNormal));
  };

  const number2RangeMaxHandler = (value) => {
    let valueNormal = "";
    if( !isNaN(value) ){
      valueNormal = Number(value);
    }

    // ulozeni maxima pro rozsahy
    setNumber2RangeMax(valueNormal);
    localStorage.setItem("number2RangeMax", JSON.stringify(valueNormal));
  };


  // ---------------------------------
  // pocet desetinnych mist
  // ---------------------------------

  const [decimals, setDecimals] = useState(
    localStorage.getItem("decimals")
      ? JSON.parse(localStorage.getItem("decimals"))
      : 0
  );

  const decimalsHandler = (value) => {
    setDecimals(value);
    localStorage.setItem("decimals", JSON.stringify(value));
  };


  // ---------------------------------
  // minimalni cislo pro generovani vysledku
  // ---------------------------------

  const [resultsMin, setResultsMin] = useState(
    localStorage.getItem("resultsMin")
      ? JSON.parse(localStorage.getItem("resultsMin"))
      : 0
  );

  const resultsMinHandler = (value) => {
    // pro prazdne policko je default hodnota 0
    if( isNaN(value) ){
        value = 0;
    }

    // pokud bude zadana velikost vetsi nez MAX tak posun MAX na MIN+1
    if( value > resultsMax ){
        const valueIncrement = value + 1;
        setNumber1RangeMax(valueIncrement);
    }

    // ulozeni minima pro rozsahy
    setResultsMin(Number(value));
    localStorage.setItem("resultsMin", JSON.stringify(value));
  };


  // ---------------------------------
  // maximalni cislo pro generovani vysledku
  // ---------------------------------

  const [resultsMax, setResultsMax] = useState(
    localStorage.getItem("resultsMax")
      ? JSON.parse(localStorage.getItem("resultsMax"))
      : 100
  );

  const resultsMaxHandler = (value) => {
    // pro prazdne policko je default hodnota 0
    if( isNaN(value) ){
        const valueIncrement = value + 1;
        value = valueIncrement;
    }

    // ulozeni maxima pro rozsahy
    setResultsMax(Number(value));
    localStorage.setItem("resultsMax", JSON.stringify(value));
  };


  // ---------------------------------
  // pocet desetinnych mist pro výsledky
  // ---------------------------------

  const [resultsDecimals, setResultsDecimals] = useState(
    localStorage.getItem("resultsDecimals")
      ? JSON.parse(localStorage.getItem("resultsDecimals"))
      : 0
  );

  const resultsDecimalsHandler = (value) => {
    setResultsDecimals(value);
    localStorage.setItem("resultsDecimals", JSON.stringify(value));
  };


  // ---------------------------------
  // seznam povolenych operatoru
  // ---------------------------------

  const [operators, setOperators] = useState({
    addition: {
      label: "Sčítání",
      value: "+",
      checked: localStorage.getItem("operatorsAddition")
        ? JSON.parse(localStorage.getItem("operatorsAddition"))
        : true,
      disabled: false,
    },
    subtraction: {
      label: "Odčítání",
      value: "-",
      checked: localStorage.getItem("operatorsSubtraction")
        ? JSON.parse(localStorage.getItem("operatorsSubtraction"))
        : true,
      disabled: false,
    },
    multiplication: {
      label: "Násobení",
      value: "×",
      checked: localStorage.getItem("operatorsMultiplication")
        ? JSON.parse(localStorage.getItem("operatorsMultiplication"))
        : true,
      disabled: false,
    },
    division: {
      label: "Dělení",
      value: ":",
      checked: localStorage.getItem("operatorsDivision")
        ? JSON.parse(localStorage.getItem("operatorsDivision"))
        : false,
      disabled: false,
    },
  });

  const operatorsAdditionHandler = (value) => {
    setOperators((prevState) => {
      return {
        ...prevState,
        addition: { ...prevState.addition, checked: value },
      };
    });
    localStorage.setItem("operatorsAddition", JSON.stringify(value));
  };

  const operatorsSubtractionHandler = (value) => {
    setOperators((prevState) => {
      return {
        ...prevState,
        subtraction: { ...prevState.subtraction, checked: value },
      };
    });
    localStorage.setItem("operatorsSubtraction", JSON.stringify(value));
  };

  const operatorsMultiplicationHandler = (value) => {
    setOperators((prevState) => {
      return {
        ...prevState,
        multiplication: { ...prevState.multiplication, checked: value },
      };
    });
    localStorage.setItem("operatorsMultiplication", JSON.stringify(value));
  };

  const operatorsDivisionHandler = (value) => {
    setOperators((prevState) => {
      return {
        ...prevState,
        division: { ...prevState.division, checked: value },
      };
    });
    localStorage.setItem("operatorsDivision", JSON.stringify(value));
  };

  //#endregion

  // ---------------------------------
  // nastaveni uzivatelskeho rozhrani
  // ---------------------------------

  const [userInterface, setUserInterface] = useState({
    onlyPositiveValues: {
      label: "Jen kladné hodnoty",
      description: "Ignoruje záporné hodnoty z rozsahu číselných hodnot",
      value: "onlyPositiveValues",
      checked: localStorage.getItem("UIOnlyPositiveValues")
        ? JSON.parse(localStorage.getItem("UIOnlyPositiveValues"))
        : false,
      disabled: false,
    },
    writtenCounting: {
      label: "Písemné počítání",
      description: "Čísla v příkladech budou zapsané pod sebou",
      value: "writtenCounting",
      checked: localStorage.getItem("UIWrittenCounting")
        ? JSON.parse(localStorage.getItem("UIWrittenCounting"))
        : false,
      disabled: false,
    },
    showResults: {
      label: "Zobrazit výsledky na obrazovce",
      description: "",
      value: "showResults",
      checked: localStorage.getItem("UIShowResults")
        ? JSON.parse(localStorage.getItem("UIShowResults"))
        : false,
      disabled: false,
    },

  });

  const UIOnlyPositiveValuesHandler = (value) => {
    setUserInterface((prevState) => {
      return {
        ...prevState,
        onlyPositiveValues: { ...prevState.onlyPositiveValues, checked: value },
      };
    });
    localStorage.setItem("UIOnlyPositiveValues", JSON.stringify(value));
  };

  const UIWrittenCountingHandler = (value) => {
    setUserInterface((prevState) => {
      return {
        ...prevState,
        writtenCounting: { ...prevState.writtenCounting, checked: value },
      };
    });
    localStorage.setItem("UIWrittenCounting", JSON.stringify(value));
  };

  const UIShowResultsHandler = (value) => {
    setUserInterface((prevState) => {
      return {
        ...prevState,
        showResults: { ...prevState.showResults, checked: value },
      };
    });
    localStorage.setItem("UIShowResults", JSON.stringify(value));
  };



  //#endregion

  // ---------------------------------
  // zadání
  // ---------------------------------

  const [description, setDescription] = useState(
    localStorage.getItem("description")
      ? JSON.parse(localStorage.getItem("description"))
      : 'Přiřaď k příkladům správné výsledky ze sloupečku "Možné výsledky" a poslední dva zbývající možné výsledky sečti a výsledné číslo zapiš pod "konečný výsledek".'
  );

  const descriptionHandler = (value) => {
    setDescription(value);
    localStorage.setItem("description", JSON.stringify(value));
  };

  // ---------------------------------
  // Tiskové sestavy
  // ---------------------------------

  const componentRef = useRef();
  const printHandler = useReactToPrint({ content: () => componentRef.current });


  // ---------------------------------
  // vlastni aplikace
  // ---------------------------------

  return (
    <React.Fragment>
      <Header />
      <Container>
        <Settings
          lists={lists}
          examples={examples}
          number1RangeMin={number1RangeMin}
          number1RangeMax={number1RangeMax}
          number2RangeMin={number2RangeMin}
          number2RangeMax={number2RangeMax}
          decimals={decimals}
          resultsMin={resultsMin}
          resultsMax={resultsMax}
          resultsDecimals={resultsDecimals}
          operators={operators}
          userInterface={userInterface}
          description={description}
          listsHandler={listsHandler}
          examplesHandler={examplesHandler}
          number1RangeMinHandler={number1RangeMinHandler}
          number1RangeMaxHandler={number1RangeMaxHandler}
          number2RangeMinHandler={number2RangeMinHandler}
          number2RangeMaxHandler={number2RangeMaxHandler}
          decimalsHandler={decimalsHandler}
          resultsMinHandler={resultsMinHandler}
          resultsMaxHandler={resultsMaxHandler}
          resultsDecimalsHandler={resultsDecimalsHandler}
          operatorsAdditionHandler={operatorsAdditionHandler}
          operatorsSubtractionHandler={operatorsSubtractionHandler}
          operatorsMultiplicationHandler={operatorsMultiplicationHandler}
          operatorsDivisionHandler={operatorsDivisionHandler}
          UIOnlyPositiveValuesHandler={UIOnlyPositiveValuesHandler}
          UIWrittenCountingHandler={UIWrittenCountingHandler}
          UIShowResultsHandler={UIShowResultsHandler}
          descriptionHandler={descriptionHandler}
          printHandler={printHandler}
        />
        <Worksheets
          lists={lists}
          examples={examples}
          number1RangeMin={number1RangeMin}
          number1RangeMax={number1RangeMax}
          number2RangeMin={number2RangeMin}
          number2RangeMax={number2RangeMax}
          decimals={decimals}
          resultsMin={resultsMin}
          resultsMax={resultsMax}
          resultsDecimals={resultsDecimals}
          operators={operators}
          userInterface={userInterface}
          description={description}
          printRef={componentRef}
        />
        <ThankYou />
      </Container>
    </React.Fragment>
  );

}

export default App;
