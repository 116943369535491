import generateNumber from "./generateNumber";
import evaluateExample from "./evaluateExample";

const generateExample = ( number1RangeMin, number1RangeMax, number2RangeMin, number2RangeMax, operator, decimals, onlyPositiveValues ) => {
  // prvni cislo
  let number1 = generateNumber( number1RangeMin, number1RangeMax, decimals, onlyPositiveValues );
  // druhe cislo
  let number2 = generateNumber( number2RangeMin, number2RangeMax, decimals, onlyPositiveValues );
  // vysledek vygenerovaneho prikladu
  let result;

  // akce pri scitani
  // if (operator === "+") {}

  // akce pri odcitani
  if (operator === "-") {
    // pokud bude operace odcitani a druhe cislo bude vetsi nez prvni tak vymenit, at nevychazi zaporne vysledky.
    if (number2 > number1 && onlyPositiveValues === true) {
      const numberTemp = number1;
      number1 = number2;
      number2 = numberTemp;
    }
  }

  // akce pri nasobeni
  // if (operator === "×") {}

  // akce pri deleni
  if (operator === ":") {
    if( number2 > number1 ){
      const numberTemp = number1;
      number1 = number2;
      number2 = numberTemp;
    }
  }

  result = evaluateExample(number1, operator, number2, decimals);

  // ulozeni informaci o prikladech
  const example = {
    number1: number1,
    operator: operator,
    number2: number2,
    result: result
  };

  return example;
}

export default generateExample;
