import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const SettingsSwitch = (props) => {
  const element = props.element;
  const elementHandler = props.elementHandler;

  return (
    <FormControlLabel
      control={
        <Switch
          value={element.checked}
          checked={element.checked}
          disabled={element.disabled}
          onChange={(e) => { elementHandler(e.target.checked); }}
        />
      }
      label={element.label}
    />
  );
};

export default SettingsSwitch;
