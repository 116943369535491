import React from "react";
import Textfield from '@mui/material/TextField';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SettingsRangeText = (props) => {
  const min = props.min;
  const max = props.max;
  const minHandler = props.minHandler;
  const maxHandler = props.maxHandler;
  const disabled = props.disabled;

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Textfield
            value={min}
            onChange={(e) => {
              minHandler(parseInt(e.target.value));
            }}
            disabled={disabled}
            type="number"
            size="small"
          />
        </Col>
        <Col>
          <Textfield
            value={max}
            onChange={(e) => {
              maxHandler(parseInt(e.target.value));
            }}
            disabled={disabled}
            type="number"
            size="small"
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SettingsRangeText;
