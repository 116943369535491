import {DelayInput} from 'react-delay-input';

import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import CachedIcon from "@mui/icons-material/Cached";

import Section from "../../UI/Section/Section";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SettingsLabel from "./SettingsLabel";
import SettingsSlider from "./SettingsSlider";
import SettingsSwitch from "./SettingsSwitch";
import SettingsRangeText from "./SettingsRangeText";

const Settings = (props) => {
  const lists = props.lists;
  const examples = props.examples;
  const number1RangeMin = props.number1RangeMin;
  const number1RangeMax = props.number1RangeMax;
  const number2RangeMin = props.number2RangeMin;
  const number2RangeMax = props.number2RangeMax;
  const decimals = props.decimals;
  const operators = props.operators;
  const userInterface = props.userInterface;
  const description = props.description;
  const listsHandler = props.listsHandler;
  const examplesHandler = props.examplesHandler;
  const number1RangeMinHandler = props.number1RangeMinHandler;
  const number1RangeMaxHandler = props.number1RangeMaxHandler;
  const number2RangeMinHandler = props.number2RangeMinHandler;
  const number2RangeMaxHandler = props.number2RangeMaxHandler;
  const decimalsHandler = props.decimalsHandler;
  const operatorsAdditionHandler = props.operatorsAdditionHandler;
  const operatorsSubtractionHandler = props.operatorsSubtractionHandler;
  const operatorsMultiplicationHandler = props.operatorsMultiplicationHandler;
  const operatorsDivisionHandler = props.operatorsDivisionHandler;
  const UIOnlyPositiveValuesHandler = props.UIOnlyPositiveValuesHandler;
  const UIWrittenCountingHandler = props.UIWrittenCountingHandler;
  const UIShowResultsHandler = props.UIShowResultsHandler;
  const descriptionHandler = props.descriptionHandler;
  const printHandler = props.printHandler;

  const checkedOperators = [];
  for (const [objectKey, objectValue] of Object.entries(operators)) {
    if (objectValue.checked) {
      checkedOperators.push(objectValue.value);
    }
  }

  const disabledSettings = checkedOperators.length <= 0 ? true : false;

  return (
    <Section>
      <Row className="mb-4">
        <Col md={6} className="mb-4 md:mb-0">
          <SettingsLabel label="Počet pracovních listů:" value={lists} />
          <SettingsSlider
            value={lists}
            elementHandler={listsHandler}
            disabled={disabledSettings}
            min={1}
            max={100}
            marks={[
              { value: 1, label: "1" },
              { value: 5 },
              { value: 10, label: "10" },
              { value: 15 },
              { value: 20, label: "20" },
              { value: 25 },
              { value: 30, label: "30" },
              { value: 35 },
              { value: 40, label: "40" },
              { value: 45 },
              { value: 50, label: "50" },
              { value: 55 },
              { value: 60, label: "60" },
              { value: 65 },
              { value: 70, label: "70" },
              { value: 75 },
              { value: 80, label: "80" },
              { value: 85 },
              { value: 90, label: "90" },
              { value: 95 },
              { value: 100, label: "100" }
            ]}
          />
        </Col>
        <Col md={6} className="mb-4 md:mb-0">
          <SettingsLabel label="Počet příkladů:" value={examples} />
          <SettingsSlider
            value={examples}
            elementHandler={examplesHandler}
            disabled={disabledSettings}
            min={1}
            max={100}
            marks={[
              { value: 1, label: "1" },
              { value: 5 },
              { value: 10, label: "10" },
              { value: 15 },
              { value: 20, label: "20" },
              { value: 25 },
              { value: 30, label: "30" },
              { value: 35 },
              { value: 40, label: "40" },
              { value: 45 },
              { value: 50, label: "50" },
              { value: 55 },
              { value: 60, label: "60" },
              { value: 65 },
              { value: 70, label: "70" },
              { value: 75 },
              { value: 80, label: "80" },
              { value: 85 },
              { value: 90, label: "90" },
              { value: 95 },
              { value: 100, label: "100" }
            ]}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md={6} xl={4} className="mb-4 xl:mb-0">
          <SettingsLabel label="Číslo 1 - rozsah:" />
          <SettingsRangeText
            min={number1RangeMin}
            max={number1RangeMax}
            minHandler={number1RangeMinHandler}
            maxHandler={number1RangeMaxHandler}
            disabled={disabledSettings}
          />
        </Col>
        <Col md={6} xl={4} className="mb-4 xl:mb-0">
          <SettingsLabel label="Číslo 2 - rozsah:" />
          <SettingsRangeText
            min={number2RangeMin}
            max={number2RangeMax}
            minHandler={number2RangeMinHandler}
            maxHandler={number2RangeMaxHandler}
            disabled={disabledSettings}
          />
        </Col>
        <Col md={12} xl={4}>
          <SettingsLabel label="Počet desetinných míst pro čísla:" value={decimals} />
          <SettingsSlider
            value={decimals}
            elementHandler={decimalsHandler}
            disabled={disabledSettings}
            min={0}
            max={5}
            marks={[
              { value: 0, label: "0" },
              { value: 1, label: "1" },
              { value: 2, label: "2" },
              { value: 3, label: "3" },
              { value: 4, label: "4" },
              { value: 5, label: "5" },
            ]}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col xs={12} lg={6} className="mb-4">
          <SettingsLabel label="Početní operace:" />
          <Row>
            <Col xs={6} md="auto">
              <SettingsSwitch
                element={operators.addition}
                elementHandler={operatorsAdditionHandler}
              />
            </Col>
            <Col xs={6} md="auto">
              <SettingsSwitch
                element={operators.subtraction}
                elementHandler={operatorsSubtractionHandler}
              />
            </Col>
            <Col xs={6} md="auto">
              <SettingsSwitch
                element={operators.multiplication}
                elementHandler={operatorsMultiplicationHandler}
              />
            </Col>
            <Col xs={6} md="auto">
              <SettingsSwitch
                element={operators.division}
                elementHandler={operatorsDivisionHandler}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <SettingsLabel label="Další nastavení:" />
          <Row>
            <Col xs={12} md="auto">
              <SettingsSwitch
                element={userInterface.onlyPositiveValues}
                elementHandler={UIOnlyPositiveValuesHandler}
                disabled={disabledSettings}
              />
            </Col>
            <Col xs={12} md="auto">
              <SettingsSwitch
                element={userInterface.writtenCounting}
                elementHandler={UIWrittenCountingHandler}
                disabled={disabledSettings}
              />
            </Col>
            <Col xs={12} md="auto">
              <SettingsSwitch
                element={userInterface.showResults}
                elementHandler={UIShowResultsHandler}
                disabled={disabledSettings}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <SettingsLabel label="Zadání:" />
          <DelayInput
            className="border border-stone-300 rounded-md w-full p-3"
            rows={4}
            element="textarea"
            delayTimeout={500}
            value={description}
            onChange={e => descriptionHandler(e.target.value)}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4}>
          <Button
            variant="contained"
            size="large"
            onClick={printHandler}
            startIcon={<PrintIcon />}
          >
            Tisk
          </Button>
        </Col>
        <Col xs={12} md={8} className="flex items-center md:justify-end">
          <Button
            variant="text"
            size="large"
            onClick={() => {
              localStorage.clear();
              window.location.reload(false);
            }}
            startIcon={<CachedIcon />}
          >
            Obnovit výchozí hodnoty
          </Button>
        </Col>
      </Row>
    </Section>
  );
};

export default Settings;
