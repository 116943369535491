import { localFormat } from "../../Helpers/format";
import "./Example.css";

const Example = (props) => {
  const number1 = localFormat(props.number1);
  const operator = props.operator;
  const number2 = localFormat(props.number2);
  const result = localFormat(props.result, false);

  return (
    <div className="flex mb-1">
      <span className="example example--number rounded mr-2">{number1}</span>
      <span className="example example--sign rounded mr-2">{operator}</span>
      <span className="example example--number rounded mr-2">{number2}</span>
      <span className="example example--sign rounded mr-2">=</span>
      <span className="example example--result rounded pl-3">{result}</span>
    </div>
  );
};

export default Example;
