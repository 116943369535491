import generateData from "../../Helpers/generateData";
import Section from "../../UI/Section/Section";
import Worksheet from "./Worksheet";
import Printsheets from "../Printsheets/Printsheets";
import React from "react";

const Worksheets = (props) => {
  const lists = props.lists;
  const examples = props.examples;
  const number1RangeMin = props.number1RangeMin;
  const number1RangeMax = props.number1RangeMax;
  const number2RangeMin = props.number2RangeMin;
  const number2RangeMax = props.number2RangeMax;
  const decimals = props.decimals;
  const operators = props.operators;
  const userInterface = props.userInterface;
  const description = props.description;
  const printRef = props.printRef;

  const checkedOperators = [];
  for (const [objectKey, objectValue] of Object.entries(operators)) {
    if (objectValue.checked) {
      checkedOperators.push(objectValue.value);
    }
  }
  const data = generateData(
    lists,
    examples,
    checkedOperators,
    number1RangeMin,
    number1RangeMax,
    number2RangeMin,
    number2RangeMax,
    decimals,
    userInterface.onlyPositiveValues.checked
  );

  return (
    <React.Fragment>
      <Section title="Pracovní listy">
        {data.map((item, key) => {
          return (
            <Worksheet
              key={key}
              index={key}
              data={item}
              userInterface={userInterface}
              description={description}
            />
          );
        })}
      </Section>
      <Printsheets
        data={data}
        userInterface={userInterface}
        description={description}
        componentRef={printRef}
      />
    </React.Fragment>
  );
};

export default Worksheets;
