import randomInteger from 'random-int';
import randomFloat from 'random-float';

const generateNumber = ( rangeMin, rangeMax, decimals, onlyPositiveValues ) => {
    let number = randomInteger(rangeMin, rangeMax);

    if( decimals > 0 ){
      number = randomFloat(rangeMin, rangeMax).toFixed(decimals);
    }

    if (onlyPositiveValues === true) {
      const abs = Math.abs(number);
      number = abs;
    }

    return number;
  }

export default generateNumber;
