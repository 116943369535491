import PrintSummary from "./PrintSummary";
import PrintPage from "./PrintPage";

const Printsheets = (props) => {
  const data = props.data;
  const userInterface = props.userInterface;
  const description = props.description;

  return (
    <div style={{ display: "none" }}>
      <div ref={props.componentRef}>
        <PrintSummary data={data} />
        {data.map((item, key) => {
          return (
            <PrintPage
              key={key}
              index={key}
              data={item}
              userInterface={userInterface}
              description={description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Printsheets;
