import React, { useState } from "react";
import Slider from "@mui/material/Slider";

const SettingsSlider = (props) => {
  const elementHandler = props.elementHandler;
  const disabled = props.disabled;
  const min = props.min;
  const max = props.max;
  const step = props.step;
  const marks = props.marks;

  const [value, setValue] = useState(props.value);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeCommitted = (event, newValue) => {
    elementHandler(newValue);
  };

  return (
    <div className="px-2">
      <Slider
        min={min}
        max={max}
        defaultValue={value}
        value={value}
        valueLabelDisplay="auto"
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        disabled={disabled}
        step={step}
        marks={marks}
      />
    </div>
  );
};

export default SettingsSlider;
