import randomInteger from 'random-int';
import arrayShuffle from "array-shuffle";
import generateNumber from "./generateNumber";
import generateExample from "./generateExample";
import generateHash from "./generateHash";

const generateData = (
  listsCounter,
  examplesCounter,
  operators,
  number1RangeMin,
  number1RangeMax,
  number2RangeMin,
  number2RangeMax,
  decimals,
  onlyPositiveValues
) => {
  // prazdne pole pro vygenerovane data
  const data = [];
  // unikatni hash pro sezeni aplikace
  const sessionHash = generateHash(3);

  // pokud budou vybrane nejake operatory tak zacni generovat priklady...
  // a zaroven budou platne rozsahy
  if (
      ( operators.length > 0 ) &&
      ( Number.isInteger(number1RangeMin) && Number.isInteger(number1RangeMax) ) &&
      ( Number.isInteger(number2RangeMin) && Number.isInteger(number2RangeMax) )
    ) {

    // spravedlive rozlozeni pocetnich operaci.
    // Pomer prikladu pro kazdy vybrany matematicky operator.
    const justiceRatio = Math.floor(examplesCounter / operators.length);
    const justiceRatioRandom = (examplesCounter % operators.length);

    // generovaci smycka pro jednotlive pracovni listy
    for (let i = 0; i < listsCounter; i++) {

      // finalni vysledek / score pracovniho listu
      let finalScore = 0;
      // pole vygenerovanych prikladu
      const examples = [];
      // pole vysledku
      const results = [];
      // pole unikatnich hashu (kontrolnich kodu)
      const allHashes = [];

      // generovaci smycka pro jednotlive pracovni listy
      // prochazeni vsech povolenych operatoru a generovani prikladu na zaklade spravedliveho pomeru prikladu
      operators.forEach(operator => {
        for (let indexRatio = 0; indexRatio < justiceRatio; indexRatio++) {
          // sestaveni celeho prikladu
          const example = generateExample(number1RangeMin, number1RangeMax, number2RangeMin, number2RangeMax, operator, decimals, onlyPositiveValues);
          // ulozeni do pole prikladu
          examples.push(example);
          // ulozeni spravneho vysledku do pole vysledku
          results.push({ value: example.result, type: "correct" });
        }
      });

      // vygenerovani zbyvajicich nahodnych prikladu na zaklade spravedliveho pomeru nahodnych prikladu
      for (let indexRatioRandom = 0; indexRatioRandom < justiceRatioRandom; indexRatioRandom++) {
        // nahodne vybrani operatoru z mnoziny vybranych operatoru
        const operator = operators[randomInteger(0, operators.length - 1)];
        // sestaveni celeho prikladu
        const example = generateExample(number1RangeMin, number1RangeMax, number2RangeMin, number2RangeMax, operator, decimals, onlyPositiveValues);
        // ulozeni do pole prikladu
        examples.push(example);
        // ulozeni do pole vysledku s priznakem "correct" - spravny vysledek
        results.push({ value: example.result, type: "correct" });
      }

      // generovaci smycka pro nahodne cisla.
      // tyto nahodne cisla jsou dulezite pro sestaveni finalniho vysledku.
      for (let indexFake = 0; indexFake < 2; indexFake++) {
        // vygenerovani nahodneho cisla
        let fakeResult = generateNumber( number1RangeMin, number1RangeMax, decimals, onlyPositiveValues );
        // pridani do pole fake vysledku
        results.push({ value: fakeResult, type: "fake" });
        // vypocet finalniho vysledku tj.soucet fake vysledku
        finalScore = finalScore + Number(fakeResult);
      }

      // generovani unikatniho hashe pracovniho listu
      let hash = '';
      let hashUniq = true;
      // generuj tak dlouho dokud bude hash unikatni - tj. vygenerovany hash neni v celkovem poli hashu
      do {
        hash = sessionHash +"-"+ generateHash(listsCounter);
        hashUniq = true;
        // vygenerovany hash neni unikatni tj. je v poli vygenerovanych hashu
        if( allHashes.includes(hash) ){ hashUniq = false; }
      } while( hashUniq === false );

      allHashes.push(hash);

      data.push({
        // hash vygenerovaneho pracovniho listu
        hash: hash,
        // vygenerovane priklady - promichani vysledku at jsou na preskacku
        // examples: examples,
        examples: arrayShuffle([...examples]),
        // vygenerovane vysledky - promichani spravnych a fake vysledku
        results: arrayShuffle([...results]),
        // finalni score pro kontrolu celeho reseni
        final: finalScore.toFixed(decimals)
      });
    }
  }

  // vysledek generovani
  return data;
};

export default generateData;
