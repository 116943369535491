import React from "react";
import Chip from "@mui/material/Chip";

const SettingsLabel = (props) => {
  const label = props.label;
  const value = props.value;

  let chipElement;
  // pokud byla zadana nejaka hodnota tak zobraz element "chip"
  if (value != null) {
    // pokud byla hodnota pole tak zobraz dva "chipy" vedle sebe
    if (value.isArray) {
      chipElement = (
        <React.Fragment>
          <Chip
            variant="outlined"
            label={value[0]}
            size="small"
          />
          <Chip
            variant="outlined"
            label={value[1]}
            size="small"
          />
        </React.Fragment>
      );
    }
    // jinak zobraz jen jeden "chip" s aktualni hodnotou
    else {
      chipElement = (
        <Chip variant="outlined" label={value} size="small" />
      );
    }
  }

  return (
    <div className="flex items-center mb-2 gap-3">
      {label}
      {chipElement}
    </div>
  );
};

export default SettingsLabel;
