import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { localFormat } from "../../Helpers/format";
import "./Print.css";

const PrintSummary = (props) => {
  const data = props.data;

  return (
  <div className="page">
      <Container fluid>
        <Row>
          <Col>Pracovní listy - Souhrné výsledky</Col>
        </Row>
      </Container>
      <div className="page__content">
        <Container fluid>
          <Row>
            <Col>
              <table>
                <thead>
                  <tr>
                    <th className="text-left">Jméno a příjmení</th>
                    <th className="text-left">Kontrolní kód</th>
                    <th className="text-left">Konečný výsledek</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td className="text-left"></td>
                        <td className="text-left">{item.hash}</td>
                        <td className="text-left">{localFormat(item.final, false)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PrintSummary;
