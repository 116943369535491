import { localFormat } from "../../Helpers/format";
import "./Result.css";

const Result = (props) => {
  const result = localFormat(props.result, false);
  const type = props.type;
  const showType = props.showType;

  let classes = "result rounded me-2 mb-2";
  if (showType) {
    classes = classes + " result--" + type;
  }

  return <div className={classes}>{result}</div>;
};

export default Result;
